:root {
    --col: 12;
    --bd-w: 1px;
    --bd: current;
    --shadow-2:rgb(100 119 131 / 39%) -15px -14px 0px 0px, rgb(0 0 0 / 45%) -32px -30px 8px 0px;
    --gap: 15px;
    --g: 16px;
    --c1: green;
    --c2: green;
    --v1: 10px;
    --v2: 10px;
    --v3: 10px;
    --accent: linear-gradient(130deg, rgba(16,55,136,1) 0%, rgba(0,189,227,1) 50%, rgb(9, 73, 177) 100%);
    --bs-color:hsl(0,0%,30%,.5);
    --bs-blur: 40px;
    --fvw:.1;
    --max-fvw: 6rem;
}

/* width */
::-webkit-scrollbar { width: 5px; }
/* Track */
::-webkit-scrollbar-track { background: #f1f1f1; }
/* Handle */
::-webkit-scrollbar-thumb { background: #888; }
/* Handle on hover */
::-webkit-scrollbar-thumb:hover { background: #555; }

/* Chrome, Firefox, Opera, Safari 10.1+ */
::placeholder { color: var(--c); opacity: 1; }
  /* Internet Explorer 10-11 */
:-ms-input-placeholder { color: var(--c); }
  /* Microsoft Edge */
::-ms-input-placeholder { color: var(--c); }

.bg-c2 {background-color: var(--color-2);}

* {
    margin: 0;
    box-sizing: border-box;
}
body{overflow-x: hidden;}
span{display: inline-block;}

.ani{ transition: all .15s ease-in-out ; }
.ani-2{ transition: all .5s ease-in-out ; }
.ani-h{transition: height .3s ease-in-out ;}
.bdw{border: 1px solid white;}
.bdb{border: 1px solid;}
.bdr{border: 1px solid red;}
.bdg{border: 1px solid green;}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,.h1 {font-size:calc(2em + min(var(--max-fvw), calc(var(--fvw) * 1vw)));}
h2,.h2 {font-size:calc(1.5em + min(var(--max-fvw), calc(var(--fvw) * 1vw)));}
h3,.h3 {font-size:calc(1.17em + min(var(--max-fvw), calc(var(--fvw) * 1vw)));}
h4,.h4 {font-size:calc(1em + min(var(--max-fvw), calc(var(--fvw) * 1vw)));}
h5,.h5 {font-size:calc(.83em + min(var(--max-fvw), calc(var(--fvw) * 1vw)));}
h6,.h6 {font-size:calc(.67em + min(var(--max-fvw), calc(var(--fvw) * 1vw)));}
p, span, a, button {font-size:calc( 1em + min(var(--max-fvw), calc(var(--fvw) * 1vw)));}

nav {
    position: absolute;
    top: 100%;
}

.df {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--gutter-x, 1.25rem);
    row-gap: var(--gutter-y, 1.25rem);
    align-content: space-around;
    align-items: center;
}
.wm-vlr{writing-mode: vertical-lr;}

.jcc{ justify-content: center; }
.jcsb{ justify-content: space-between; }
.jcsa{ justify-content: space-around; }
.jcse{ justify-content: space-evenly; }
.overflow-hidden{overflow: hidden;}
.no-wrap {flex-wrap: nowrap;}
.nowrap { white-space: nowrap !important; }

.fg{flex-grow: 1;}
.fs {flex-shrink: 1;}
.ais {align-items: stretch;}
.acfs {align-content: flex-start;}

.dib{display: inline-block;}

[class~=hover] { transition: all .15s ease-in-out ;}
.hover-1 {transition: all .15s ease-in-out ;}
.hover-1:hover { transform: scale(1.1); }
.hover-2 {transition: all .15s ease-in-out ;}
.hover-2:hover { transform: translateY(-5px); }

.clip-1 { clip-path: polygon( 0 -20%, 100% -20%, 100% 80%, 50% 100%, 0 80%); }
.clip-2 { clip-path: polygon(50% 90%, 100% 0, 100% 100%, 0 100%, 0 0); }
.clip_3 { clip-path: polygon( 0 0, 100% 0, 100% calc(100% - 54vw), 0 100%); }
.translate-center{
    translate: -50% -50%;
}
.bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}
.bg-fit {
    background-size: cover;
    background-repeat: no-repeat;
}
.gradient-1 {
    background: rgb(2,0,34); 
    background: linear-gradient(130deg, rgba(2,0,34,1) 0%, rgba(16,55,136,1) 34%, rgba(0,189,227,1) 100%);
}
.gradient-light {
    background: rgb(2,0,34); 
    background: linear-gradient(130deg, rgba(16,55,136,1) 0%, rgba(0,189,227,1) 50%, rgb(9, 73, 177) 100%);
}
.gradient-clip-1 {
    background: rgb(2,0,34); 
    background: linear-gradient(130deg, rgba(2,0,34,1) 0%, rgba(16,55,136,1) 34%, rgba(0,189,227,1) 100%);
    background-size: cover;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.gradient-clip-light {
    background: rgb(2,0,34); 
    background: linear-gradient(130deg, rgba(16,55,136,1) 0%, rgba(0,189,227,1) 50%, rgb(9, 73, 177) 100%);
    background-size: cover;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.gradient-2 {
    background: rgb(93,96,99);
    background: linear-gradient(316deg, rgb(20 21 22) 0%, rgb(55 62 70) 51%, rgb(31 39 48) 100%);
}

.scale-0{
    height: 0;
    width: 0;
    transform: scale(0);
    display: none;
}
#contact-bg {
    background-image: url(https://unsplash.it/400/400); 
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    min-height: 60vw;
}

.gallery_div{
    display: block;
    white-space: nowrap;
}
.gallery_div >*:not(:first-child):not(:last-child){
    margin-left: 50px;
    margin-right: 50px;
}
.gal-main {
    display: inline-block;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
}
.gal_block {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    gap: max(30px, 3vw);
    justify-content: space-evenly;
}

.gap {gap: var(--gap)}
.vertical {  writing-mode: vertical-lr;}

.fade-in {
	animation: fadeIn 1s;
  	opacity: 1;
    z-index: 20;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-out {
	animation: fadeOut .3s;
    opacity: 0;
}
@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}
.fade-out-2 {
    /* animation: fadeOut-2 .8s; */
    opacity: 0;
}
/* @keyframes fadeOut-2 {
  from { opacity: 1; }
  to { opacity: 0; }
} */

.fade-out-right {
    transform: translateX(100%);
    opacity: 0;
}
.shrink-out-right {
    transform: translateX(100%);
    height: 0px !important;
}

.h-0{ height: 0px !important; }
.w-0{ 
    width: 0px !important; 
    height: 50% !important; 
    overflow-x: hidden;
}

.menu_box {
    position: absolute;
    top: 150%;
    right: 0;
    width: max-content;
    border-radius: 8px;
    overflow: hidden;
    z-index: 20;
}

.abs {position: absolute; }
.rel {position: relative; }

.vat{vertical-align: top;}
.vam {vertical-align: middle;}

.inner-shadow {
    box-shadow: 0px 1px 14px 9px rgba(0,0,0,0.28) inset;
    -webkit-box-shadow: 0px 1px 14px 9px rgba(0,0,0,0.28) inset;
    -moz-box-shadow: 0px 1px 14px 9px rgba(0,0,0,0.28) inset;
}
.inner-shadow-1 {
    box-shadow: 1px 1px 11px 0px rgb(55 83 161 / 38%) inset;
    -webkit-box-shadow: 1px 1px 11px 0px rgb(55 83 161 / 38%) inset;
    -moz-box-shadow: 1px 1px 11px 0px rgb(55 83 161 / 38%) inset;
}
.inner-shadow-2 {
    box-shadow: 3px 3px 12px 4px rgba(0,0,0,0.16) inset;
    -webkit-box-shadow: 3px 3px 12px 4px rgba(0,0,0,0.16) inset;
    -moz-box-shadow: 3px 3px 12px 4px rgba(0,0,0,0.16) inset;
}

.clip_2 { clip-path: polygon(50% 31%, 100% 0, 100% 70%, 50% 100%, 0 70%, 0 0); }
.obj-cover {object-fit: cover;}
.italic {
    display: inline-block; 
    vertical-align: .05em; 
    font-family: cursive;
}
.min{width:min-content;}


.bg-blur {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.5)
}

.gallery_item {
    cursor: pointer;
    height: min(400px, max(16vw, 200px));
    aspect-ratio: 1;
    margin-bottom: 1em;
}

.nav-mask {
    -webkit-mask-image: -webkit-gradient(linear, 0% 70%, 0% 100%, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: -webkit-gradient(linear, 0% 70%, 0% 100%, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
}

.after-1::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 75%;
    right: 0;
    bottom: 0;
    -webkit-mask-image: -webkit-gradient(linear, 50% 45%, 8% 40%, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
    mask-image: -webkit-gradient(linear, 50% 45%, 8% 40%, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
}

.fade { opacity: 0; }
.fade-1 {
    -webkit-mask-image: linear-gradient(270deg, black 40%, transparent 75%);
    mask-image: linear-gradient(270deg, black 40%, transparent 75%);
}

#blue-gradient {
    -webkit-mask-image: linear-gradient(90deg, black 50%, transparent 100%);
    mask-image: linear-gradient(90deg, black 50%, transparent 100%);
}

/* .avatar-bg { background-image: url('./img/Carlos-1.png'); } */
.avatar-1 { 
    --c1:rgba(148, 186, 223, 0.3);
    --c2:rgb(51 71 99 / 70%);
    --xy: 16;
    background-image: url('./img/Carlos-2.jpg '); 
    box-shadow: 0px 0px 0px 1px rgba(138,138,138,0.75), calc(var(--xy) * 1px) calc(var(--xy) * 1px) 0px 0px var(--c1), calc(var(--xy) * 2px) calc(var(--xy) * 2px) 8px 1px var(--c2);
    -webkit-box-shadow: 0px 0px 0px 1px rgba(138,138,138,0.75), calc(var(--xy) * -1px) calc(var(--xy) * -1px) 0px 0px var(--c1), calc(var(--xy) * -2px) calc(var(--xy) * -2px) 8px 1px var(--c2);
    -moz-box-box-shadow: 0px 0px 0px 1px rgba(138,138,138,0.75), calc(var(--xy) * -1px) calc(var(--xy) * -1px) 0px 0px var(--c1), calc(var(--xy) * -2px) calc(var(--xy) * -2px) 8px 1px var(--c2);
}
.portfolio-span{
    color:rgba(14, 14, 14, 0.5);
}

@media (max-width: 767px) {
    .gallery_item {
        height: min(400px, max(75vw, 200px)) !important;
    }

    .after-1::after {
        width: 100% !important;
        -webkit-mask-image: -webkit-gradient(linear, 0% 50%, 0% 78%, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0))) !important;
        mask-image: -webkit-gradient(linear, 0% 50%, 0% 78%, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0))) !important;
    }

    .fade-1 {
        -webkit-mask-image: linear-gradient(180deg, black 40%, transparent 75%);
        mask-image: linear-gradient(180deg, black 40%, transparent 75%);
    }

    #blue-gradient {
        --v1:20%;
        --v2:70%;
        width: 100%;
        -webkit-mask-image: linear-gradient(0deg, black var(--v1), transparent var(--v2));
        mask-image: linear-gradient(0deg, black var(--v1), transparent var(--v2));
    }
}

.bg-img-1 { 
    background-image: url('./img/img-1.jpg'); 
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

.direct-child-fx > * { transition: all .15s ease-in-out; }
.direct-child-fx:hover > * { transform: scale(1.1); }

.cus-bg-1{background-color: hsl(210,11%,10%,.8);}

.gradient-clip-1 {
    background: rgb(2, 0, 34);
    background: linear-gradient(130deg, rgba(2, 0, 34, 1) 0%, rgba(16, 55, 136, 1) 34%, rgba(0, 189, 227, 1) 100%);
    background-size: cover;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-section-gradient {
    -webkit-mask-image: -webkit-gradient(linear, 0% 100%, 0% 0%, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
    mask-image: -webkit-gradient(linear, 0% 100%, 0% 0%, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
}
.text-shadow-1{text-shadow: 0px 0px 23px rgb(213 239 255);}

.shadow-dark-2 { box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.35) !important; }
.p-mb > p {
    margin-bottom: 0;
}

.line-count{
    counter-reset: line-count;
}
.line-count > p {
    position: relative;
    margin-bottom: 0;
    line-height: 1.8em;
    padding-right: 10px;
    display: flex;
    font-weight: var(--v3);
}
.line-count > p::before {
    counter-increment: line-count;
    content:counter(line-count);
    position: absolute;
    left: var(--v1);
    transform: translateY(-50%);
    bottom:-50%;
    font-size:.8em;
    font-weight: 500;
}
.text-mask{
    background: var(--accent);
    background-size: cover;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.cus-mask-size{
    -webkit-mask-size: 119%;
    mask-size: 119%;
}
a{
    color: inherit;
    text-decoration: none;
}

/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */

.d-inline { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block { display: block !important; }
.d-grid { display: grid !important; }
.d-table { display: table !important; }
.d-table-row { display: table-row !important; }
.d-table-cell { display: table-cell !important; }
.d-flex { display: flex !important; }
.d-inline-flex { display: inline-flex !important; }
.d-none { display: none !important; }
.d-flex { display: flex !important; }
.flex-fill { flex: 1 1 auto !important; }
.flex-row { flex-direction: row !important; }
.flex-column { flex-direction: column !important; }
.flex-row-reverse { flex-direction: row-reverse !important; }
.flex-column-reverse { flex-direction: column-reverse !important; }
.flex-grow-0 { flex-grow: 0 !important; }
.flex-grow-1 { flex-grow: 1 !important; }
.flex-shrink-0 { flex-shrink: 0 !important; }
.flex-shrink-1 { flex-shrink: 1 !important; }
.flex-wrap { flex-wrap: wrap !important; }
.flex-nowrap { flex-wrap: nowrap !important; }
.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

[style*=--svg\:] {
    mask-image: var(--svg);
    -webkit-mask-image: var(--svg); 
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-position: 50%;
    mask-position: 50%;
}

[style*=--container\;], .container {
    width: 100%;
    padding-right: var(--gutter-x, 0.75rem);
    padding-left: var(--gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
}

[style*=--row\;] {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: calc(var(--gutter-y) * -1);
    margin-right: calc(var(--gutter-x) * -.5);
    margin-left: calc(var(--gutter-x) * -.5);
}

[style*=--row\;] > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--gutter-x) * .5);
    padding-left: calc(var(--gutter-x) * .5);
    margin-top: var(--gutter-y);
}

[style*=--col] {
    display: flex;
    flex-wrap: wrap;
    gap: var(--g, 16px);
    width: 100%;
    flex: 1 0 0%;
    max-width: calc((100%/12) * min(var(--col), 12))
}

[style*=--col\:] {flex: unset;}
[style*=--offset\:] {margin-left: calc((100%/12) * min(var(--offset), 11));}

[style*=--g\:] {
    --gutter-x: calc(0.25rem * var(--g));
    --gutter-y: calc(0.25rem * var(--g));
    column-gap: calc(0.25rem * var(--g));
    row-gap: calc(0.25rem * var(--g));
}

[style*=--gx\:] { --gutter-x: calc(0.25rem * var(--gx)); }
[style*=--gy\:] { --gutter-y: calc(0.25rem * var(--gy)); }
[style*=--col-g\:] { column-gap: calc(0.25rem * var(--col-g)); }
[style*=--row-g\:] { row-gap: calc(0.25rem * var(--row-g)); }
[style*=--row-cols\:]>* {
    flex: 0 0 auto;
    width: calc(100% / var(--row-cols))
}

[style*=--row-cols-auto]>* { width: auto; }

[style*=--d\:] { display: var(--d) }
[style*=--gap\:] { gap: var(--gap) }
[style*=--x\:] { transform: translateX(var(--x)) }
[style*=--y\:] { transform: translateY(var(--y)) }
[style*=--z\:] { z-index: var(--z); }
[style*=--pos\:] { position: var(--pos) }
[style*=--top\:] { top: var(--top) }
[style*=--bottom\:] { bottom: var(--bottom) }
[style*=--left\:] { left: var(--left) }
[style*=--right\:] { right: var(--right) }
[style*=--op\:] { opacity: var(--op) }

[style*=--w\:] { width: var(--w) }
[style*=--minw\:] { min-width: var(--minw) }
[style*=--maxw\:] { max-width: var(--maxw) }

[style*=--h\:] { height: var(--h) }
[style*=--minh\:] { min-height: var(--minh) }
[style*=--maxh\:] { max-height: var(--maxh) }

[style*=--wh\:] { width:var(--wh); height: var(--wh) }

[style*=--ar\:] { aspect-ratio: var(--ar) }
[style*=--fs\:] { font-size: calc(var(--fs) + calc(var(--fvw) * 1vw)) }
[style*=--fw\:] { font-weight: var(--fw) }
[style*=--lh\:] { line-height: var(--lh) }
[style*=--c\:] { color: var(--c) }
[style*=--focus-c\:]:focus { color: var(--focus-c) }
[style*=--hover-c\:]:hover { color: var(--hover-c) }


[style*=--m\:] { margin: calc(0.25em * var(--m)) }
[style*=--mx\:] { margin-left: calc(0.25em * var(--mx)); margin-right: calc(0.25em * var(--mx)) }
[style*=--my\:] { margin-top: calc(0.25em * var(--my)); margin-bottom: calc(0.25em * var(--my)) }
[style*=--mt\:] { margin-top: calc(0.25em * var(--mt)) }
[style*=--mb\:] { margin-bottom: calc(0.25em * var(--mb)) }
[style*=--ml\:] { margin-left: calc(0.25em * var(--ml)) }
[style*=--mr\:] { margin-right: calc(0.25em * var(--mr)) }
[style*=--ch-m\:] > * { margin: calc(0.25em * var(--ch-m)) }
[style*=--ch-mx\:] > * { margin-left: calc(0.25em * var(--ch-mx)); margin-right: calc(0.25em * var(--ch-mx)) }
[style*=--ch-my\:] > * { margin-top: calc(0.25em * var(--ch-my)); margin-bottom: calc(0.25em * var(--ch-my)) }
[style*=--ch-mt\:] > * { margin-top: calc(0.25em * var(--ch-mt)) }
[style*=--ch-mb\:] > * { margin-bottom: calc(0.25em * var(--ch-mb)) }
[style*=--ch-ml\:] > * { margin-left: calc(0.25em * var(--ch-ml)) }
[style*=--ch-mr\:] > * { margin-right: calc(0.25em * var(--ch-mr)) }

[style*=--p\:] { padding: calc(0.25em * var(--p)) }
[style*=--px\:] { padding-left: calc(0.25em * var(--px)); padding-right: calc(0.25em * var(--px)) }
[style*=--py\:] { padding-top: calc(0.25em * var(--py)); padding-bottom: calc(0.25em * var(--py)) }
[style*=--pt\:] { padding-top: calc(0.25em * var(--pt)) }
[style*=--pb\:] { padding-bottom: calc(0.25em * var(--pb)) }
[style*=--pl\:] { padding-left: calc(0.25em * var(--pl)) }
[style*=--pr\:] { padding-right: calc(0.25em * var(--pr)) }

[style*=--ep\:] { padding: calc(0.25em * var(--ep)) }
[style*=--epx\:] { padding-left: calc(0.25em * var(--epx)); padding-right: calc(0.25em * var(--epx)) }
[style*=--epy\:] { padding-top: calc(0.25em * var(--epy)); padding-bottom: calc(0.25em * var(--epy)) }
[style*=--ept\:] { padding-top: calc(0.25em * var(--ept)) }
[style*=--epb\:] { padding-bottom: calc(0.25em * var(--epb)) }
[style*=--epl\:] { padding-left: calc(0.25em * var(--epl)) }
[style*=--epr\:] { padding-right: calc(0.25em * var(--epr)) }

[style*=--ch-p\:] > * { padding: calc(0.25em * var(--ch-p)) }
[style*=--ch-px\:] > * { padding-left: calc(0.25em * var(--ch-px)); padding-right: calc(0.25em * var(--ch-px)) }
[style*=--ch-py\:] > * { padding-top: calc(0.25em * var(--ch-py)); padding-bottom: calc(0.25em * var(--ch-py)) }
[style*=--ch-pt\:] > * { padding-top: calc(0.25em * var(--ch-pt)) }
[style*=--ch-pb\:] > * { padding-bottom: calc(0.25em * var(--ch-pb)) }
[style*=--ch-pl\:] > * { padding-left: calc(0.25em * var(--ch-pl)) }
[style*=--ch-pr\:] > * { padding-right: calc(0.25em * var(--ch-pr)) }

/* border */
[style*=--bd\:] { border: var(--bd-w) solid var(--bd) }
[style*=--br\:] { border-radius: var(--br) }
/* background */
[style*=--bg\:] { background: var(--bg) }
[style*=--bg-size\:] { background-size: var(--bg-size) }
[style*=--blur\:] { backdrop-filter: blur(calc(var(--blur) * 1px)); -webkit-backdrop-filter: blur(calc(var(--blur) * 1px)); }
[style*=--bs\:] { box-shadow: var(--bs-color) 0px 0px var(--bs-blur) var(--bs);
}

[style*=--focus-bg\:]:focus { background-color: var(--focus-bg) }
[style*=--active-bg\:]:active { background-color: var(--active-bg) }
[style*=--hover-bg\:]:hover { background-color: var(--hover-bg) }
[style*=--hover-y\:]:hover { translate: 0 var(--hover-y) }
[style*=--hover-scale\:]:hover { scale: var(--hover-scale) }

@media(min-width:576px) {
    .d-sm-inline {display: inline !important;}
    .d-sm-inline-block {display: inline-block !important;}
    .d-sm-block {display: block !important;}
    .d-sm-grid {display: grid !important;}
    .d-sm-table {display: table !important;}
    .d-sm-table-row {display: table-row !important;}
    .d-sm-table-cell {display: table-cell !important;}
    .d-sm-flex {display: flex !important;}
    .d-sm-inline-flex {display: inline-flex !important;}
    .d-sm-none {display: none !important;}
    .sm-jcc{justify-content: center;}
    .sm-jcfs{justify-content: flex-start;}

    [style*=--container\;] { max-width: 540px; }
    [style*=--row-cols-sm]>* { width: calc(100% / min(var(--row-cols-sm), 6)) }
    [style*=--col-sm\:] { max-width: calc((100%/12) * var(--col-sm)) }
    [style*=--offset-sm\:] { margin-left: calc((100%/12) * min(var(--offset-sm), 11)); }
    [style*=--g-sm\:] { --gutter-x: calc(0.25rem * var(--g-sm)); }
    [style*=--g-sm\:] { --gutter-y: calc(0.25rem * var(--g-sm)); }
    [style*=--gx-sm\:] { --gutter-x: calc(0.25rem * var(--gx-sm)); }
    [style*=--gy-sm\:] { --gutter-y: calc(0.25rem * var(--gy-sm)); }
    [style*=--w-sm\:] { width: var(--w-sm) }
    [style*=--minw-sm\:] { min-width: var(--minw-sm) }
    [style*=--maxw-sm\:] { max-width: var(--maxw-sm) }
    [style*=--h-sm\:] { height: var(--h-sm) }
    [style*=--minh-sm\:] { min-height: var(--minh-sm) }
    [style*=--maxh-sm\:] { max-height: var(--maxh-sm) } 
    
    [style*=--p-sm\:] { padding: calc(0.25rem * var(--p-sm)) }
    [style*=--pt-sm\:] { padding-top: calc(0.25rem * var(--pt-sm)) }
    [style*=--pb-sm\:] { padding-bottom: calc(0.25rem * var(--pb-sm)) }
    [style*=--pl-sm\:] { padding-left: calc(0.25rem * var(--pl-sm)) }
    [style*=--pr-sm\:] { padding-right: calc(0.25rem * var(--pr-sm)) }
    [style*=--px-sm\:] { padding-left: calc(0.25rem * var(--px-sm)); padding-right: calc(0.25rem * var(--px-sm)) }
    [style*=--py-sm\:] { padding-top: calc(0.25rem * var(--py-sm)); padding-bottom: calc(0.25rem * var(--py-sm)) }

    [style*=--m-sm\:] { margin: calc(0.25rem * var(--m-sm)) }
    [style*=--mt-sm\:] { margin-top: calc(0.25rem * var(--mt-sm)) }
    [style*=--mb-sm\:] { margin-bottom: calc(0.25rem * var(--mb-sm)) }
    [style*=--ml-sm\:] { margin-left: calc(0.25rem * var(--ml-sm)) }
    [style*=--mr-sm\:] { margin-right: calc(0.25rem * var(--mr-sm)) }
    [style*=--mx-sm\:] { margin-left: calc(0.25rem * var(--mx-sm)); margin-right: calc(0.25rem * var(--mx-sm)) }
    [style*=--my-sm\:] { margin-top: calc(0.25rem * var(--my-sm)); margin-bottom: calc(0.25rem * var(--my-sm)) }

    [style*=--fs-sm\:] { font-size: var(--fs-sm) }
}

@media(min-width:768px) {
    .d-md-inline {display: inline !important;}
    .d-md-inline-block {display: inline-block !important;}
    .d-md-block {display: block !important;}
    .d-md-grid {display: grid !important;}
    .d-md-table {display: table !important;}
    .d-md-table-row {display: table-row !important;}
    .d-md-table-cell {display: table-cell !important;}
    .d-md-flex {display: flex !important;}
    .d-md-inline-flex {display: inline-flex !important;}
    .d-md-none {display: none !important;}
    .md-jcc{justify-content: center;}
    .md-jcfs{justify-content: flex-start;}

    [style*=--container\;] { max-width: 720px; }
    [style*=--row-cols-md]>* { width: calc(100% / min(var(--row-cols-md), 6)) }
    [style*=--col-md\:] { max-width: calc((100%/12) * var(--col-md)) }
    [style*=--offset-md\:] { margin-left: calc((100%/12) * min(var(--offset-md), 11)); }
    [style*=--g-md\:] { --gutter-x: calc(0.25rem * var(--g-md)); }
    [style*=--g-md\:] { --gutter-y: calc(0.25rem * var(--g-md)); }
    [style*=--gx-md\:] { --gutter-x: calc(0.25rem * var(--gx-md)); }
    [style*=--gy-md\:] { --gutter-y: calc(0.25rem * var(--gy-md)); }
    [style*=--w-md\:] { width: var(--w-md) }
    [style*=--minw-md\:] { min-width: var(--minw-md) }
    [style*=--maxw-md\:] { max-width: var(--maxw-md) }
    [style*=--h-md\:] { height: var(--h-md) }
    [style*=--minh-md\:] { min-height: var(--minh-md) }
    [style*=--maxh-md\:] { max-height: var(--maxh-md) } 
    
    [style*=--p-md\:] { padding: calc(0.25rem * var(--p-md)) }
    [style*=--pt-md\:] { padding-top: calc(0.25rem * var(--pt-md)) }
    [style*=--pb-md\:] { padding-bottom: calc(0.25rem * var(--pb-md)) }
    [style*=--pl-md\:] { padding-left: calc(0.25rem * var(--pl-md)) }
    [style*=--pr-md\:] { padding-right: calc(0.25rem * var(--pr-md)) }
    [style*=--px-md\:] { padding-left: calc(0.25rem * var(--px-md)); padding-right: calc(0.25rem * var(--px-md)) }
    [style*=--py-md\:] { padding-top: calc(0.25rem * var(--py-md)); padding-bottom: calc(0.25rem * var(--py-md)) }

    [style*=--m-md\:] { margin: calc(0.25rem * var(--m-md)) }
    [style*=--mt-md\:] { margin-top: calc(0.25rem * var(--mt-md)) }
    [style*=--mb-md\:] { margin-bottom: calc(0.25rem * var(--mb-md)) }
    [style*=--ml-md\:] { margin-left: calc(0.25rem * var(--ml-md)) }
    [style*=--mr-md\:] { margin-right: calc(0.25rem * var(--mr-md)) }
    [style*=--mx-md\:] { margin-left: calc(0.25rem * var(--mx-md)); margin-right: calc(0.25rem * var(--mx-md)) }
    [style*=--my-md\:] { margin-top: calc(0.25rem * var(--my-md)); margin-bottom: calc(0.25rem * var(--my-md)) }

    [style*=--fs-md\:] { font-size: var(--fs-md) }
}

@media(min-width:992px) {
    .d-lg-inline {display: inline !important;}
    .d-lg-inline-block {display: inline-block !important;}
    .d-lg-block {display: block !important;}
    .d-lg-grid {display: grid !important;}
    .d-lg-table {display: table !important;}
    .d-lg-table-row {display: table-row !important;}
    .d-lg-table-cell {display: table-cell !important;}
    .d-lg-flex {display: flex !important;}
    .d-lg-inline-flex {display: inline-flex !important;}
    .d-lg-none {display: none !important;}
    .lg-jcc{justify-content: center;}
    .lg-jcfs{justify-content: flex-start;}

    [style*=--container\;] { max-width: 960px; }
    [style*=--row-cols-lg]>* { width: calc(100% / min(var(--row-cols-lg), 6)) }
    [style*=--col-lg\:] { max-width: calc((100%/12) * var(--col-lg)) }
    [style*=--offset-lg\:] { margin-left: calc((100%/12) * min(var(--offset-lg), 11)); }
    [style*=--g-lg\:] { --gutter-x: calc(0.25rem * var(--g-lg)); }
    [style*=--g-lg\:] { --gutter-y: calc(0.25rem * var(--g-lg)); }
    [style*=--gx-lg\:] { --gutter-x: calc(0.25rem * var(--gx-lg)); }
    [style*=--gy-lg\:] { --gutter-y: calc(0.25rem * var(--gy-lg)); }
    [style*=--w-lg\:] { width: var(--w-lg) }
    [style*=--minw-lg\:] { min-width: var(--minw-lg) }
    [style*=--maxw-lg\:] { max-width: var(--maxw-lg) }
    [style*=--h-lg\:] { height: var(--h-lg) }
    [style*=--minh-lg\:] { min-height: var(--minh-lg) }
    [style*=--maxh-lg\:] { max-height: var(--maxh-lg) } 
    
    [style*=--p-lg\:] { padding: calc(0.25rem * var(--p-lg)) }
    [style*=--pt-lg\:] { padding-top: calc(0.25rem * var(--pt-lg)) }
    [style*=--pb-lg\:] { padding-bottom: calc(0.25rem * var(--pb-lg)) }
    [style*=--pl-lg\:] { padding-left: calc(0.25rem * var(--pl-lg)) }
    [style*=--pr-lg\:] { padding-right: calc(0.25rem * var(--pr-lg)) }
    [style*=--px-lg\:] { padding-left: calc(0.25rem * var(--px-lg)); padding-right: calc(0.25rem * var(--px-lg)) }
    [style*=--py-lg\:] { padding-top: calc(0.25rem * var(--py-lg)); padding-bottom: calc(0.25rem * var(--py-lg)) }

    [style*=--m-lg\:] { margin: calc(0.25rem * var(--m-lg)) }
    [style*=--mt-lg\:] { margin-top: calc(0.25rem * var(--mt-lg)) }
    [style*=--mb-lg\:] { margin-bottom: calc(0.25rem * var(--mb-lg)) }
    [style*=--ml-lg\:] { margin-left: calc(0.25rem * var(--ml-lg)) }
    [style*=--mr-lg\:] { margin-right: calc(0.25rem * var(--mr-lg)) }
    [style*=--mx-lg\:] { margin-left: calc(0.25rem * var(--mx-lg)); margin-right: calc(0.25rem * var(--mx-lg)) }
    [style*=--my-lg\:] { margin-top: calc(0.25rem * var(--my-lg)); margin-bottom: calc(0.25rem * var(--my-lg)) }

    [style*=--fs-lg\:] { font-size: var(--fs-lg) }
}

@media(min-width:1200px) {
    .d-xl-inline {display: inline !important;}
    .d-xl-inline-block {display: inline-block !important;}
    .d-xl-block {display: block !important;}
    .d-xl-grid {display: grid !important;}
    .d-xl-table {display: table !important;}
    .d-xl-table-row {display: table-row !important;}
    .d-xl-table-cell {display: table-cell !important;}
    .d-xl-flex {display: flex !important;}
    .d-xl-inline-flex {display: inline-flex !important;}
    .d-xl-none {display: none !important;}
    .xl-jcc{justify-content: center;}
    .xl-jcfs{justify-content: flex-start;}

    [style*=--container\;] { max-width: 1140px; }
    [style*=--row-cols-xl]>* { width: calc(100% / min(var(--row-cols-xl), 6)) }
    [style*=--col-xl\:] { max-width: calc((100%/12) * var(--col-xl)) }
    [style*=--offset-xl\:] { margin-left: calc((100%/12) * min(var(--offset-xl), 11)); }
    [style*=--g-xl\:] { --gutter-x: calc(0.25rem * var(--g-xl)); }
    [style*=--g-xl\:] { --gutter-y: calc(0.25rem * var(--g-xl)); }
    [style*=--gx-xl\:] { --gutter-x: calc(0.25rem * var(--gx-xl)); }
    [style*=--gy-xl\:] { --gutter-y: calc(0.25rem * var(--gy-xl)); }
    [style*=--w-xl\:] { width: var(--w-xl) }
    [style*=--minw-xl\:] { min-width: var(--minw-xl) }
    [style*=--maxw-xl\:] { max-width: var(--maxw-xl) }
    [style*=--h-xl\:] { height: var(--h-xl) }
    [style*=--minh-xl\:] { min-height: var(--minh-xl) }
    [style*=--maxh-xl\:] { max-height: var(--maxh-xl) } 
    
    [style*=--p-xl\:] { padding: calc(0.25rem * var(--p-xl)) }
    [style*=--pt-xl\:] { padding-top: calc(0.25rem * var(--pt-xl)) }
    [style*=--pb-xl\:] { padding-bottom: calc(0.25rem * var(--pb-xl)) }
    [style*=--pl-xl\:] { padding-left: calc(0.25rem * var(--pl-xl)) }
    [style*=--pr-xl\:] { padding-right: calc(0.25rem * var(--pr-xl)) }
    [style*=--px-xl\:] { padding-left: calc(0.25rem * var(--px-xl)); padding-right: calc(0.25rem * var(--px-xl)) }
    [style*=--py-xl\:] { padding-top: calc(0.25rem * var(--py-xl)); padding-bottom: calc(0.25rem * var(--py-xl)) }

    [style*=--m-xl\:] { margin: calc(0.25rem * var(--m-xl)) }
    [style*=--mt-xl\:] { margin-top: calc(0.25rem * var(--mt-xl)) }
    [style*=--mb-xl\:] { margin-bottom: calc(0.25rem * var(--mb-xl)) }
    [style*=--ml-xl\:] { margin-left: calc(0.25rem * var(--ml-xl)) }
    [style*=--mr-xl\:] { margin-right: calc(0.25rem * var(--mr-xl)) }
    [style*=--mx-xl\:] { margin-left: calc(0.25rem * var(--mx-xl)); margin-right: calc(0.25rem * var(--mx-xl)) }
    [style*=--my-xl\:] { margin-top: calc(0.25rem * var(--my-xl)); margin-bottom: calc(0.25rem * var(--my-xl)) }

    [style*=--fs-xl\:] { font-size: var(--fs-xl) }
}

@media(min-width:1400px) {
    .d-xxl-inline {display: inline !important;}
    .d-xxl-inline-block {display: inline-block !important;}
    .d-xxl-block {display: block !important;}
    .d-xxl-grid {display: grid !important;}
    .d-xxl-table {display: table !important;}
    .d-xxl-table-row {display: table-row !important;}
    .d-xxl-table-cell {display: table-cell !important;}
    .d-xxl-flex {display: flex !important;}
    .d-xxl-inline-flex {display: inline-flex !important;}
    .d-xxl-none {display: none !important;}
    .xxl-jcc{justify-content: center;}
    .xxl-jcfs{justify-content: flex-start;}

    [style*=--container\;] { max-width: 1320px; }
    [style*=--row-cols-xxl]>* { width: calc(100% / min(var(--row-cols-xxl), 6)) }
    [style*=--col-xxl\:] { max-width: calc((100%/12) * var(--col-xxl)) }
    [style*=--offset-xxl\:] { margin-left: calc((100%/12) * min(var(--offset-xxl), 11)); }
    [style*=--g-xxl\:] { --gutter-x: calc(0.25rem * var(--g-xxl)); }
    [style*=--g-xxl\:] { --gutter-y: calc(0.25rem * var(--g-xxl)); }
    [style*=--gx-xxl\:] { --gutter-x: calc(0.25rem * var(--gx-xxl)); }
    [style*=--gy-xxl\:] { --gutter-y: calc(0.25rem * var(--gy-xxl)); }
    [style*=--w-xxl\:] { width: var(--w-xxl) }
    [style*=--minw-xxl\:] { min-width: var(--minw-xxl) }
    [style*=--maxw-xxl\:] { max-width: var(--maxw-xxl) }
    [style*=--h-xxl\:] { height: var(--h-xxl) }
    [style*=--minh-xxl\:] { min-height: var(--minh-xxl) }
    [style*=--maxh-xxl\:] { max-height: var(--maxh-xxl) } 

    [style*=--p-xxl\:] { padding: calc(0.25rem * var(--p-xxl)) }
    [style*=--pt-xxl\:] { padding-top: calc(0.25rem * var(--pt-xxl)) }
    [style*=--pb-xxl\:] { padding-bottom: calc(0.25rem * var(--pb-xxl)) }
    [style*=--pl-xxl\:] { padding-left: calc(0.25rem * var(--pl-xxl)) }
    [style*=--pr-xxl\:] { padding-right: calc(0.25rem * var(--pr-xxl)) }
    [style*=--px-xxl\:] { padding-left: calc(0.25rem * var(--px-xxl)); padding-right: calc(0.25rem * var(--px-xxl)) }
    [style*=--py-xxl\:] { padding-top: calc(0.25rem * var(--py-xxl)); padding-bottom: calc(0.25rem * var(--py-xxl)) }

    [style*=--m-xxl\:] { margin: calc(0.25rem * var(--m-xxl)) }
    [style*=--mt-xxl\:] { margin-top: calc(0.25rem * var(--mt-xxl)) }
    [style*=--mb-xxl\:] { margin-bottom: calc(0.25rem * var(--mb-xxl)) }
    [style*=--ml-xxl\:] { margin-left: calc(0.25rem * var(--ml-xxl)) }
    [style*=--mr-xxl\:] { margin-right: calc(0.25rem * var(--mr-xxl)) }
    [style*=--mx-xxl\:] { margin-left: calc(0.25rem * var(--mx-xxl)); margin-right: calc(0.25rem * var(--mx-xxl)) }
    [style*=--my-xxl\:] { margin-top: calc(0.25rem * var(--my-xxl)); margin-bottom: calc(0.25rem * var(--my-xxl)) }

    [style*=--fs-xxl\:] { font-size: var(--fs-xxl) }
}

/* --------------------------------------------------------------- */
